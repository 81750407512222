var emailText;

$(document).ready(function () {
    "use strict";
    
    $(".signUpDealsShortForm  #emailTerm").attr("placeholder", $("#emailAddressLabel").val());
	$(".icon-toggle-footer").click(function() {
		 $(this).find("span").toggleClass("minusImg");
	});

    $(".icon-toggle-refer").click(function() {
        $(this).find("span.icon-arrow-toggle").toggleClass("arrowDown");
    });
	
		
    /** * Sign up email validation header ** */
    var emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    $(".greenEmailInput input#emailTerm ,.signUpClass input#signUpDeals-Email").on('input propertychange', function () {
        if (emailPattern.test($(this).val())) {
            $(this).parents('form').find('.blue-tooltip').popover('hide');
        } else {
            if ($(this).val()) {
                $(this).parents('form').find('.blue-tooltip').attr('data-original-title', ACC.emailRequired);
            } else {
                $(this).parents('form').find('.blue-tooltip').attr('data-original-title', ACC.fieldRequired);
            }
            $(this).parents('form').find('.blue-tooltip').popover('show');
        }
    });
    
    $(".greenEmailInput input#emailTerm ,.signUpClass input#signUpDeals-Email").blur(function () {
        $(this).parents('form').find('.blue-tooltip').popover('hide');
    });
    
	$(".greenEmailInput input#emailTerm").keypress(function (e) {
		var key = e.which;
		if(key == 13) {
			$('.greenEmailInput .input-group-addon').click();
			return false;  
		}
	});
	
	$(".signUpClass input#signUpDeals-Email").keypress(function (e) {
		var key = e.which;
		if(key == 13) {
			$('.signUpClass .signUpBtn').click();
			return false;  
		}
	});
	$(".racLinks .menu-parent-wrapper , .submenu-title").click(function(){
	    var url = $(this).find("a").attr("href");
	    if(url != undefined){
 	        window.location = url; return false;
 	    } else {
 	        return false;
 	    }
 	});
    
    $(".greenEmailInput .input-group-addon").click(function() {
        var _this = this;
        emailText = $(this).parent('div').find('input.signup-email').val();
        if (!emailPattern.test(emailText)) {

            if ($(this).parent('div').find('input.signup-email').val()) {
                if ($(_this).parents().hasClass("greenContainerDesktop")) {
                    $('.greenContainerDesktop').find('.blue-tooltip').attr('data-original-title', ACC.emailRequired);
                } else {
                    $('.greenContainerMobile').find('.blue-tooltip').attr('data-original-title', ACC.emailRequired);
                }
            } else {
                if ($(_this).parents().hasClass("greenContainerDesktop")) {
                    $('.greenContainerDesktop').find('.blue-tooltip').attr('data-original-title', ACC.fieldRequired);
                } else {
                    $('.greenContainerMobile').find('.blue-tooltip').attr('data-original-title', ACC.fieldRequired);
                }
            }
            $(this).parents('form').find('.blue-tooltip').popover('show');
            return false;
        }
        submitSignUpForEmail(_this);
    });

    $(document).on('click', '.signUpClass .signUpBtn', function () {
        var _this = this;
        emailText = $(this).parents('.signUpClass').find('input.signup-email').val();
        if (!emailPattern.test(emailText)) {

            if ($(this).parents('div').find('input.signup-email').val()) {
                $(_this).parents('form').find('.blue-tooltip').attr('data-original-title', ACC.emailRequired);
            } else {
                $(_this).parents('form').find('.blue-tooltip').attr('data-original-title', ACC.fieldRequired);
            }
            $(this).parents('form').find('.blue-tooltip').popover('show');
            return false;
        }
        submitSignUpForEmail(_this);
    });

    //start of TCS code merge 6th Oct
    if ($(".specialRacImg").length) {
        $(".specialRacImg:first").css("padding-right", "10px");
        $(".specialRacImg:last").css("padding-left", "10px");
    }
    //end of TCS code merge 6th Oct

    RAC.Header.init();

	$("#RACHomePageRotatingImageComponent").swipe({
		swipeLeft: function(event, direction, distance, duration, fingerCount) {
			$(this).carousel('next');
		},
		swipeRight: function(event, direction, distance, duration, fingerCount) {
			$(this).carousel('prev');
		},
		threshold: 25,
	});

});

$(window).on('load', function () {
	//Popular Categories Carousel
    var popularCategoryCarousel = $('.popular-categories-carousel');
    popularCategoryCarousel.on('initialized.owl.carousel', function() {
        $('.popular-categories-child2').fadeIn(300);
        $(".popular-categories-child1").removeClass('pre-loader');
    });
    popularCategoryCarousel.owlCarousel({
		margin: 0,
		loop: false,
		autoWidth: true,
		nav: false,
        lazyLoad: true,
		dots: false
	});
    // Medallia Modal Scripts
    $('#medalliaModal').on('shown.bs.modal', function() {
        loadScriptOnDemand($('#medalliaJsUrl').val());  // Load Medallia Js Script
        $("#medalliaModal").modal({
            backdrop: 'static',
            keyboard: false
        });
    });
    var medalliaFlag = document.getElementById('enableMedallia').value;
    if (medalliaFlag === 'true') {
        if (getCookieByName('signUpAccepted') != 'Yes' && getCookieByName('signUpDeclined') != 'Yes') {
 	            setTimeout(function(){$("#medalliaModal").modal()},$('#medalliaDisplayDelayTime').attr('data-value'));
        }
    }

    //Animation for homepage search placeholder
	if ($('body').hasClass('page-racHomePage')) {
		let categoryTitles = $('.category-product .category-product-title');
		if (categoryTitles.length > 0) {
			let categoriesTitlesIndex = 0;
			let searchInputBox = $('#mainSearchForm .siteSearchInput');
			$(searchInputBox).addClass('rotating-text');
			let originalPlaceholder = $(searchInputBox).attr('placeholder');
			let categoryTitlesArray = categoryTitles.toArray().map(function(element) {
				return $(element).text();
			});
			function updatePlaceholder() {
				var nextPlaceholder = categoryTitlesArray[categoriesTitlesIndex];
				$(searchInputBox).attr('placeholder', originalPlaceholder + ' ' + nextPlaceholder);
				categoriesTitlesIndex = (categoriesTitlesIndex + 1) % categoryTitlesArray.length;
			}
			const changeInterval = $('#homepageSearchTimer').attr('data-value');
			setInterval(updatePlaceholder, changeInterval);
		}
	}
});

function submitSignUpForEmail(_this) {
    var successContent;
	var signUpForSpecialsData = {
		"emailAddress": emailText
	};
    $.ajax({
        url: "/rac-specials/sign-up-for-dealsViaEmail",
        type: "post",
		data: JSON.stringify(signUpForSpecialsData),
        contentType: 'application/json',
        success: function (data) {
            if (data== null) {
                $(_this).parents(".signUpDealsShortForm").find(".form-group:first").css("display", "none");
                successContent = $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html();
                $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html("<p>" +ACC.userFacingProblem +"</p>")
                $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").css("display", "block");
                setTimeout(function () {
                    $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").css("display", "none");
                    $(_this).parents(".signUpDealsShortForm").find(".form-group:first").css("display", "block");
                    $(_this).parents(".signUpDealsShortForm").trigger('reset');
                    $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html(successContent);
                    if ($(_this).hasClass("js-SignUPDesktop")) {
                        $("#signUpSpecialBtn").trigger('click');
                    } else if ($(_this).hasClass("js-SignUPMobile")) {
                        $("#signUpDealsbuttonMobile").trigger('click');
                    } else {
                        $(".signUpDealsFooter").css("display", "block");
                        $("#signUpBtn").css("display", "inline-block");

                    }
                }, 10000)
            } else if (data != null && (data.statusCode == "200" || data.statusCode == "200 OK")) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'email-signup',
                    'emailSource': 'HomePage sign-up'
                });
                if ($(_this).parents().hasClass("greenContainerDesktop")) {
                    $('.greenContainerDesktop').find('.signUpDealsShortForm').addClass("hidden");
                    $('.greenContainerDesktop').find('.signup-success').removeClass("hidden");
                } else if ($(_this).parents().hasClass("greenContainerMobile")) {
                    $('.greenContainerMobile').find('.signUpDealsShortForm').addClass("hidden");
                    $('.greenContainerMobile').find('.signup-success').removeClass("hidden");
                } else {
                    $(_this).parents('form').addClass("hidden");
                    $(_this).parents('form').parent().find('.signup-success').removeClass("hidden");
                }
                setTimeout(function () {
                    $(_this).parents('form').parent().find('.signup-success').addClass("hidden");
                    $(_this).parents('form').removeClass("hidden");
                    $(_this).parents('div').find('input.signup-email').val('');
                }, 10000)
            }
            else {
                $(_this).parents(".signUpDealsShortForm").find(".form-group:first").css("display", "none");
                successContent = $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html();
                $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html("<p class='signUpError'><span>" +ACC.havingTechnical +"</span> <span>" +ACC.difficultiesTryAgain +"</span></p>")
                $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").css("display", "block");
                setTimeout(function () {
                    $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").css("display", "none");
                    $(_this).parents(".signUpDealsShortForm").find(".form-group:first").css("display", "block");
                    $(_this).parents(".signUpDealsShortForm").trigger('reset');
                    $(_this).parents(".signUpDealsShortForm").find(".signUpSuccess").html(successContent);
                    if ($(_this).hasClass("js-SignUPDesktop")) {
                        $("#signUpSpecialBtn").trigger('click');
                    } else if ($(_this).hasClass("js-SignUPMobile")) {
                        $("#signUpDealsbuttonMobile").trigger('click');
                    } else {
                        $(".signUpDealsFooter").css("display", "block");
                        $("#signUpBtn").css("display", "inline-block");

                    }
                }, 10000)
            }
        },
        error: function (error) {
        }
    });
    return false;
}